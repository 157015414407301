
#googleMap{
	.gm-style-cc{
	     display:none;
	}
	.gmnoprint{
	     display:none;
	}
}

.ant-drawer-content-wrapper{
	height: calc(100vh - 105px)!important;
	top:64px;
}
.ant-drawer-content-wrapper .handle {
  position: absolute;
  top: 240px;
  right: 500px;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  font-size: 16px;
  text-align: center;
  background: #096dd9;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
  pointer-events: auto;
}

.personAvatar{
	margin:20px 0 30px;
	text-align: center;
}

.personInfo{
	font-size:18px;
	color:#666;
    text-align:left;
    .ant-row{
    	margin-top:20px;
    	.ant-col:nth-of-type(even){
    		color:#333;
    		font-size: 19px;
    	}
    }
}



$primary-color:#1890ff;
.login{
    height: 100vh;
    background:url('../../images/login_bg.png') center center;
    background-size: cover;
    overflow:hidden;
    position:relative;
    .login-title{
    	text-align:center;
    	padding-top:150px;	
    }
	.login-form {
	   position:absolute;
	   max-width: 400px;
	   padding:20px 30px;
	   top: 300px;
	   left: 50%;
	   margin-left:-165px;
	}
	.ant-form-item{
		margin-bottom: 50px;
	}
	.ant-input{
		border:0;
		outline: none;
		padding:0;
		border-bottom:1px solid #999;
		border-radius: 0;
		outline:none!important;
		height:40px;
		line-height: 40px;
		background-color: rgba(255,255,255,1)!important;
		color:#333!important;
		font-size: 24px;
		-webkit-appearance: none;
		&:focus{
			border:0;
			outline: none;
			border-bottom:1px solid #999;
			box-shadow:none;
		};
	}
	input:-webkit-autofill{
		border:0;
		border-bottom:1px solid #999;
        -webkit-text-fill-color:#333!important;
		-webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
		background-image: none;
		outline:none!important;
		transition: background-color 50000s ease-in-out 0s;
    }
	.login-form-forgot {
	  float: right;
	}
	.login-form-button {
	  width: 120px;
	  height:40px;
	  line-height: 40px;
	  font-size: 24px;
	  background-color: #a6b5b8;
	  border:0;
	  margin:0 auto;
	  border-radius:0;
	  color:#333;
	  font-weight: 500;
	  display: inline-block;
	}
}

@media screen and (max-width: 700px) {
 .login-title{
	text-align:center;
	padding-top:100px!important;	
	img{
	    max-width:70%;
	}
 }
 .login-form {
	   position:absolute;
	   max-width: 400px;
	   padding:20px 30px;
	   top: 200px!important;
	   left: 50%;
	   margin-left:-165px;
	}
}

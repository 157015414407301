.customize {
    .trigger {
        font-size: 18px;
        line-height: 64px;
        padding: 0 24px;
        cursor: pointer;
        transition: color .3s;
        &:hover {
          color: #1890ff;
        }
    }
    .logo {
        height: 36px;
        margin:14px 20px;
        h1{
          color:#fff;
          line-height:36px;
          padding-left:5px;
          margin-bottom:0px;
        }
    }
    .user{
        float:right;
        margin-right:35px;
        span{
            display: inline-block;
            margin:0 5px;
        }
    }
}



.customize .logo h1.small{
  font-size:16px;
}

.customize .logo h1.large{
  font-size:24px;
}

.customize .content{
  margin: 0;
  padding: 0;
  background: #fff;
  min-height: calc(100vh - 181px);;
}

.customize .ant-menu-dark .ant-menu-item a{
  color: rgba(255, 255, 255, 0.65)!important;
}

.customize .ant-menu-dark .ant-menu-item:hover a,
.customize .ant-menu-dark .ant-menu-item-selected a,
.customize .ant-menu-dark .ant-menu-item-active a{
  color:rgba(255, 255, 255, 1)!important;
}

.customize .mb20{
  margin-bottom: 20px;
}
.customize .pd20{
  padding:20px;
}
.customize .gray{
  background:#f1f1f1;
}
.customize .box{
  padding:20px;
  background:#fff;
  height:calc(100vh - 105px)
}
.customize .box .ant-table-row b{
  width:16px;
  height:16px;
  display:inline-block;
  border-radius:8px;
}
.customize .box .ant-table-row b.green{
  background:#0f0;
}
.customize .box .ant-table-row b.red{
  background:#f00;
}
.customize .box .ant-table-row b.gray{
  background:#999;
}
.customize .table-operations {
  margin-bottom: 16px;
}

.customize .table-operations > button {
  margin-left: 10px;
}
.ant-layout-footer{
  padding: 10px 50px;
}
.ant-upload-list-item-info{
  display:none;
}

.geofencing{
    position: relative;
    .btn{
      position: absolute;
      top:10px;
      left:10px;
      z-index: 99;
      button{
        margin-right:15px;
      }
    }
}
.home{
   position: relative;
   .info{
      position: absolute;
      top:0;
      left:0;
      z-index: 99;
      font-size: 14px;
      color:#333;
      background: rgba(255,255,255,0.5);
      width: 100%;
      font-weight: bolder;
      height:40px;
      line-height: 40px;
      span{
         margin-left: 25px;
      }
   }
}
.history{
  position: relative;
  .range{
    position: absolute;
    z-index: 99;
    bottom:0;
    left:0;
    height:50px;
    background: rgba(200,200,200,0.6);
    width:100%;
    padding:0 100px;
    .ant-slider-mark-text{
      color:#333;
      font-size: 14px;
      min-width: 150px;
      font-weight: bolder;
    }
  }
}
.uuid{
    padding-top:30px;
    .btn{
        margin-top:28px;
        margin-right:20px;
    }
}



@media screen and (max-width: 700px) {
   .ant-layout-sider-collapsed{
      flex: 0 0 0px!important;
      max-width: 0px!important;
      min-width: 0px!important;
      width: 0px!important;
      overflow:hidden!important;
   }
   .personInfo{
      padding:0 25px;
   }
   .ant-drawer-open{
       .ant-drawer-content-wrapper{
            width:300px!important;
       }
       .ant-drawer-body{
            padding:0;
       }
       .ant-drawer-content-wrapper .handle{
            right:300px;
       }
    }
    .ant-calendar-picker{
        width:100%!important;
    } 
  .home .info>span{
      display:none;
      &.search{
        display:block;
        margin-left:0;
        text-align:center;
      }
   }
  .profiles{
    .ant-table-content{
       overflow-x: auto;
    }
    .ant-select{
      margin-left:10px;
      width:130px!important;
      margin-bottom:10px;
    }
    button{
        margin-bottom:10px;
    }
  }
}



    